<!-- =========================================================================================
    File Name: Login.vue
    Description: Login Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
  <div class="h-screen flex w-full bg-img vx-row no-gutter items-center justify-center" id="page-login">
    <div class="vx-col sm:w-1/2 md:w-1/2 lg:w-3/4 xl:w-3/5 sm:m-0 m-4">
      <vx-card>
        <div slot="no-body" class="full-page-bg-color">

          <div class="vx-row no-gutter justify-center items-center">

            <div class="vx-col hidden lg:block lg:w-1/2">
              <img src="@/assets/images/pages/login.png" alt="login" class="mx-auto img-login">
            </div>

            <div class="vx-col sm:w-full md:w-full lg:w-1/2 d-theme-dark-bg">
              <div class="p-8 login-tabs-container">

                <div class="vx-card__title mb-4">
                  <h4 class="mb-4">Portal Ambev - Águas Claras</h4>
                  <p>Bem vindo, por favor, logue com sua conta.</p>
                </div>

                <div>
                  <vs-input
                      name="ambevId"
                      icon-no-border
                      icon="icon icon-user"
                      icon-pack="feather"
                      label-placeholder="ID Ambev"
                      v-model="ambevId"
                      class="w-full"/>
                    <span class="text-danger text-sm">{{ errors.first('ambevId') }}</span>

                  <vs-input
                      name="birthdate"
                      icon-no-border
                      icon="icon icon-lock"
                      icon-pack="feather"
                      label-placeholder="Data de nascimento"
                      v-model="birthdate"
                      v-mask="'##/##/####'"
                      class="w-full mt-6" />
                    <span class="text-danger text-sm">{{ errors.first('birthdate') }}</span>

                  <div class="flex flex-wrap justify-between my-5">
                      <vs-checkbox v-model="checkbox_remember_me" class="mb-3">Lembrar-me</vs-checkbox>
                  </div>

                  <vs-button class="float-right" :disabled="!validateForm" @click="login" >Entrar</vs-button>
                  <div class="flex flex-wrap justify-between my-5"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
export default{
  data() {
    return {
      ambevId: "",
      birthdate: "",
      checkbox_remember_me: false,
    }
  },
  computed: {
    validateForm() {
      return !this.errors.any() && this.ambevId != "" && this.birthdate != "";
    }
  },
  methods: {
    checkLogin() {
      // If user is already logged in notify
      if (this.$store.state.auth.isUserLoggedIn()) {
        // Close animation if passed as payload
        // this.$vs.loading.close()

        this.$vs.notify({
          title: "Login",
          text: "Você já está logado",
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "warning"
        });

        return false;
      }
      return true;
    },
    login() {
      // if (!this.checkLogin()) return

      // Loading
      this.$vs.loading();
      // const birthdateParts = this.birthdate.split("/")
      // const birthdate = `${birthdateParts[2]}-${birthdateParts[1]}-${birthdateParts[0]}`

      const payload = {
        checkbox_remember_me: this.checkbox_remember_me,
        userDetails: {
          ambevId: this.ambevId,
          birthdate: this.birthdate
        }
      };

      //{"ambevId":"99999999","birthdate":"1983-11-10"}
      this.$store
        .dispatch("auth/loginJWT", payload)
        .then(() => {
          this.$vs.loading.close();
        })
        .catch(() => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Que pena!",
            text: "Usuário não encontrado",
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
            position: "top-center"
          });
        });
    },
  }
}
</script>

<style lang="scss">
#page-login {
  .social-login-buttons {
    .bg-facebook { background-color: #1551b1 }
    .bg-twitter { background-color: #00aaff }
    .bg-google { background-color: #4285F4 }
    .bg-github { background-color: #333 }
  }
}
.img-login {
  height: 200px;
}
</style>
